<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script type="text/javascript" src="https://js.cdn.aliyun.dcloud.net.cn/dev/uni-app/uni.webview.1.5.2.js"></script> 

<script>

export default {
  name: "App",
  components: {},
  created() {
    // if (this.$router.path !== "/") this.$router.replace("/");
  },
  mounted() {
    // setInterval(function () {
    //   var startTime = performance.now();
    //   debugger;
    //   var endTime = performance.now();
    //   if (endTime - startTime > 100) {
    //     window.location.href = "about:blank";
    //   }
    // }, 100);
    // //监听用户敲击键盘事件
    // window.addEventListener("keydown", this.handleKeydown);
    // //阻止右键打开菜单
    // document.oncontextmenu = function (event) {
    //   event.preventDefault();
    // };
  },
  methods: {
    handleKeydown(event) {
      //如果键盘敲击f12就阻止f12打开，然后弹框提醒，这个都放在app.vue里面，当然在页面还没有加载的时候最好在index.html里面也加上对应的方法
      if (event.key === "F12") {
        event.preventDefault(); // 阻止F12的默认行为
        Modal.error({
          title: "警告",
          content: "检测非法调试，F12被管理员禁用",
          okText: "知道了",
        });
      }
    },
  },
};
</script>

<style>
#app {
  /*文字居中*/
  /* display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; */
  height: 100%;
}

* {
  /*初始化样式*/
  margin: 0;
  padding: 0;
}

html {
  /*用于 获取 屏幕的可视宽高*/
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* // 大写的PX，不会被postcss-pxtorem转化为rem */
  /* font-size: 100px;
  font-size: 26.66666667vw; */
}

/*让 body 初始 width 和 height 就 等于 页面可视区域的 宽高*/
body {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/* @media screen and (orientation: portrait) {
  body {
    transform-origin: 0 0;
    transform: rotateZ(90deg) translateY(-100%);
  }
} */
/*竖屏样式*/
.back {
  position: absolute;
  z-index: 999999;
  width: 24px;
  height: 24px;
  top: 20px;
  left: 40px;
}
.common_btn {
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 8px;
  background-color: #f57a32;
  color: #fff;
}
</style>
