import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  // mode:'history',
  routes: [
    {
      path: '/',
      name: 'interface',
      component: () => import('@/views/interface.vue'),
      meta: {
        requiresAuth: true
      }
    }
  ],
  linkActiveClass: 'active', // 配置模糊匹配的类名
  // linkExactActiveClass: 'exact-active' // 配置精确匹配的类名
})
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject) return originalPush.call(this, location, resolve, reject)
  return originalPush.call(this, location).catch((e) => { })
}